import React, {Component} from 'react';
import Base from './Base';
import TransactionLog from "./TransactionLog";

class ListOfTransactionLog extends Component {

  render() {
    const message = this.props.message;
    let h = <h1>Transaction Logs</h1>;
    const transactionLogs = this.props.transactionLogs;
    console.log(transactionLogs);
    const isDone = this.props.isDone;
    if (isDone === true) {

      return <div className='ListOfTransactionLog'>
        <Base/>
        {h}
        {

          transactionLogs.map(
              transactionLog => <TransactionLog key={transactionLog.id}
                                  id={transactionLog.id}
                                  user_id={transactionLog.user_id}
                                  amount={transactionLog.amount}
                                  status={transactionLog.status}
                                  payment_id={transactionLog.payment_id}
                                  billing_customer_id = {transactionLog.billing_customer_id}
                                  createdAt={transactionLog.createdAt}
              ></TransactionLog>)
        }

      </div>;
    } else {
      return (<div><Base/>{h}{message}</div>)
    }
  }

  componentDidMount() {
    this.props.fetchTransactionLogs();
  }
}

export default ListOfTransactionLog;
