import {connect} from 'react-redux'
import ListOfCraiglistUser from '../presentations/ListOfCraiglistUser';
import {saveUser, remove, fetchUsers} from '../actions/CraiglistUserAction';

const mapStateToProps = state => ({
    craiglistUsers: state.craiglistusers.craiglistUsers,
    isDone: state.craiglistusers.isDone,
    message: state.craiglistusers.message

})

const mapDispatchToProps = dispatch => ({
    fetchUsers: () => dispatch(fetchUsers()),
    onSubmit: (user) => dispatch(saveUser(user)),
    onDelete: (user) => dispatch(remove(user))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOfCraiglistUser)
