import { connect } from 'react-redux'
import Users from '../presentations/Users';
// import { fetchUsers } from '../actions/UsersAction';

const mapStateToProps = state => ({
// user: state.user.user,
// isDone : state.user.isDone
  
})

const mapDispatchToProps = dispatch => ({
  // fetch : () =>dispatch(fetchUsers())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)
