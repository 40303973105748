import React, { Component } from "react";
import Base from "./Base";
import Axios from "axios";
import { Constants } from "../utils/Constants";
import {
  Col,
  Row,
  Container,
  InputGroup,
  FormControl,
  Toast,
} from "react-bootstrap";
import UsersGrid from "./UsersGrid";

const company = {
  c_: "craiglist",
  l_: "letgo",
  o_: "offerup",
};

const eventList = {
  isLoginFailed: "Login status",
  start_posting: "Posting status",
  copyPass: "Copy password",
};
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      show: false,
      sreachEmail: "",
      toastData: {
        toastHead: "kk",
        small: "",
        toastBody: "kk",
      },
    };
  }
  setId = (event) => {
    const selectedId = event.target.id;
    this.setState({
      selectedId: this.state.selectedId === +selectedId ? null : +selectedId,
    });
  };

  componentDidMount() {
    this.getAllUsersData();
  }

  getAllUsersData = () => {
    Axios.get(Constants.BASE_URL + "users/all/", {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then((response) => {
        this.setState({
          usersList: response.data,
        });
      })
      .catch(() => {});
  };
  onChangeEmail = (event) => {
    const sreachEmail = event.target.value;
    this.setState({
      sreachEmail,
    });
  };

  changeState = (key, data, prefix) => {
    const payload = {
      [key]: !!data[prefix + key] ? 0 : 1,
      user_id: data.id,
      id: data[prefix + "id"],
      email: data[prefix + "email"],
    };
    this.closeToast(false);
    Axios.put(Constants.BASE_URL + `users/${company[prefix]}/admin`, payload, {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then(() => {
        this.toogleToast(payload, key, true);
        this.getAllUsersData();
      })
      .catch(() => {
        this.toogleToast(payload, key, false);
      });
  };
  toogleToast = (payload, key, bool) => {
    const toastBody =
      eventList[key] + (bool ? " successfully " : " failed to ") + "changed";
    const toastData = {
      toastHead: payload.email,
      toastBody,
    };
    this.setState({ show: !this.state.show, toastData });
  };
  closeToast = (show) => {
    this.setState({ show });
  };
  render() {
    const users = this.state.usersList.filter(
      (user) =>
        user.email.includes(this.state.sreachEmail) ||
        (user.c_email &&
          user.c_email
            .toLowerCase()
            .includes(this.state.sreachEmail.toLowerCase())) ||
        (user.o_email &&
          user.o_email
            .toLowerCase()
            .includes(this.state.sreachEmail.toLowerCase()))
    );
    return (
      <div>
        <Base />
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <div>
            <Toast
              style={{
                position: "absolute",
                padding: 0,
                top: 50,
                right: 0,
              }}
              onClose={() => this.closeToast(false)}
              show={this.state.show}
              delay={3000}
              autohide
            >
              <Toast.Header
                style={{
                  color: "green",
                  border: "1px solid grey",
                }}
              >
                <strong className="mr-auto">
                  {this.state.toastData.toastBody}
                </strong>
                <small>{this.state.toastData.small}</small>
              </Toast.Header>
            </Toast>
          </div>
          <div>
            <Container fluid>
              <Row>
                <Col lg={4} xs={6} md={4}>
                  <InputGroup
                    className="mb-3"
                    value={this.state.sreachEmail}
                    onChange={this.onChangeEmail}
                  >
                    <FormControl
                      aria-label="Small"
                      placeholder="Search email ..."
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </div>

          <div>
            {!users.length ? (
              <div style={{ textAlign: "center" }}>Loading...</div>
            ) : (
              <UsersGrid users={users} changeState={this.changeState} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Users;
