import {connect} from 'react-redux'
import ListOfTransactionLog from '../presentations/ListOfTransactionLog';
import {fetchTransactionLogs} from '../actions/TransactionLogAction';

const mapStateToProps = state => ({
    transactionLogs: state.transactionLogs.transactionLogs,
    isDone: state.transactionLogs.isDone,
    message: state.transactionLogs.message

})

const mapDispatchToProps = dispatch => ({
    fetchTransactionLogs: () => dispatch(fetchTransactionLogs())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOfTransactionLog)
