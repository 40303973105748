import React from "react";

import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import StatusColumn from "./StatusColumn";
import EmailTooltip from "./EmailTooltip";

class UsersGrid extends React.Component {
  render() {
    return (
      <Table responsive striped hover className="align-center" size="md">
        <thead>
          <tr>
            <th>#</th>
            <th className="align-left">Email</th>
            <th>Status</th>
            <th className="align-left">Email (Offerup)</th>
            <th>Status</th>
            <th className="align-left">Email (Craiglist)</th>
          </tr>
        </thead>
        <tbody>
          {this.props.users.map((element) => (
            <tr>
              <td>{element.id}</td>
              <td className="align-left break-word">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip style={{ border: "none" }}>
                      <div>
                        <b>Name:</b> {element.name} <br />
                        <b>Phone No:</b> {element.phone_no}
                      </div>
                    </Tooltip>
                  }
                >
                  <Button className="pd-0" variant="link">
                    {element.email}
                  </Button>
                </OverlayTrigger>
              </td>

              <td>
                {element.o_email ? (
                  <StatusColumn
                    element={element}
                    prefix="o_"
                    changeState={this.props.changeState}
                    isLoginFailed={element.o_isLoginFailed}
                    start_posting={element.o_start_posting}
                  />
                ) : null}
              </td>

              <td className="align-left break-word">
                <EmailTooltip
                  placement="top"
                  createdAt={element.o_createdAt}
                  updatedAt={element.o_updatedAt}
                  email={element.o_email}
                />
              </td>

              <td>
                {element.c_email ? (
                  <StatusColumn
                    element={element}
                    prefix="c_"
                    changeState={this.props.changeState}
                    isLoginFailed={element.c_isLoginFailed}
                    start_posting={element.c_start_posting}
                  />
                ) : null}
              </td>

              <td className="align-left break-word">
                <EmailTooltip
                  placement="top"
                  createdAt={element.c_createdAt}
                  updatedAt={element.c_updatedAt}
                  email={element.c_email}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
export default UsersGrid;
