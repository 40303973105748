import React, { Component } from 'react';
import Base from './Base';

class Home extends Component {
  render() {
    const isDone = this.props.isDone;
    let progress = <div className="Home">Hello admin</div>;
    if (isDone === true) {
      progress = <div className="Home">Hello Admin</div>;
    }
    return (
      <div>
        <Base />
        {progress}
      </div>
    );
  }
}

export default Home;
