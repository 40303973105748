import React, { Component } from "react";
import Base from "./Base";
import PostLog from "./PostLog";
import {
  Table,
  ButtonGroup,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

class ListOfPostLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 30,
      offset: 0,
    };
  }
  offsetOnChange = (i) => {
    const newOffset = this.state.offset + i;
    this.setState({ offset: newOffset });
    this.props.fetchPostLogs(this.state.limit, newOffset);
  };

  render() {
    const message = this.props.message;
    const postLogs = this.props.postLogs;
    const users = this.props.users;
    const isDone = this.props.isDone;
    if (isDone === true) {
      return (
        <div className="ListOfPostLog">
          <Base />
          <Container fluid>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Col>
                <h3>LOGS</h3>
              </Col>

              <Col lg={6}>
                <span style={{ float: "right" }}>
                  <ButtonGroup>
                    <Button
                      variant="dark"
                      disabled={this.state.offset === 0}
                      onClick={() => this.offsetOnChange(-30)}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <Button
                      variant="outline-dark"
                      onClick={() => this.offsetOnChange(30)}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </ButtonGroup>
                </span>
              </Col>
            </Row>
          </Container>
          <div
            style={{
              margin: "0 auto",

              paddingBottom: 10,
            }}
          >
            {postLogs.length ? (
              <Table
                striped
                hover
                responsive
                size="md"
                className="align-center"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="align-left">Email</th>
                    <th>Status</th>
                    <th>Company</th>
                    <th className="align-left">Details</th>
                    <th>Time</th>
                    <th className="align-left">UTC</th>
                  </tr>
                </thead>

                <tbody>
                  {postLogs.map((postLog) => (
                    <PostLog
                      key={postLog.id}
                      id={postLog.id}
                      title={postLog.title}
                      user={users.find((user) => user.id === postLog.user_id)}
                      body={postLog.body}
                      price={postLog.price}
                      postingCity={postLog.postingCity}
                      isSuccess={postLog.isSuccess}
                      createdAt={postLog.createdAt}
                      type={postLog.type}
                      postalCode={postLog.postalCode}
                    ></PostLog>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h3 style={{ textAlign: "center" }}>No more logs</h3>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Base />
          {message}
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.fetchPostLogs(this.state.limit, this.state.offset);
  }
}

export default ListOfPostLog;
