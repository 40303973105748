import {
  ACTION_FETCH_SERVICES_FAILED,
  ACTION_FETCH_SERVICES_SUCCESS,
} from './actions';
import Axios from 'axios';
import { Constants } from '../utils/Constants';

export function fetchServicesSuccess(services) {
  return {
    type: ACTION_FETCH_SERVICES_SUCCESS,
    services,
  };
}

export function fetchServicesFailed(message) {
  return {
    type: ACTION_FETCH_SERVICES_FAILED,
    message,
  };
}

export function fetchServices() {
  return function(dispatch) {
    return Axios.get(Constants.BASE_URL + 'users/letgo/admin', {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then(response => {
        console.log(response.data);
        dispatch(fetchServicesSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchServicesFailed(error.message));
      });
  };
}

export function saveService(service) {
  return function(dispatch) {
    if (service.id === undefined || service.id === null) {
      return Axios.post(Constants.BASE_URL + 'users/letgo', service, {
        headers: Constants.REQUEST_HEADER_JSON,
      })
        .then(response => {
          dispatch(fetchServices());
        })
        .catch(error => {
          dispatch(fetchServicesFailed(error.message));
          dispatch(fetchServices());
        });
    } else {
      return Axios.put(Constants.BASE_URL + 'users/letgo/admin', service, {
        headers: Constants.REQUEST_HEADER_JSON,
      })
        .then(response => {
          dispatch(fetchServices());
        })
        .catch(error => {
          dispatch(fetchServicesFailed(error.message));
          dispatch(fetchServices());
        });
    }
  };
}

export function remove(service) {
  return function(dispatch) {
    return Axios.delete(
      Constants.BASE_URL + 'users/letgo/admin/' + service.id,
      { headers: Constants.REQUEST_HEADER_JSON }
    )

      .then(response => {
        dispatch(fetchServices());
      })
      .catch(error => {
        dispatch(fetchServicesFailed(error.message));
        dispatch(fetchServices());
      });
  };
}
