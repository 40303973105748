import React, { Component } from 'react';
import CraiglistUser from './CraiglistUser';
import Base from './Base';

class ListOfCraiglistUser extends Component {
  render() {
    const craiglistUsers = this.props.craiglistUsers;
    const onSubmit = this.props.onSubmit;
    const isDone = this.props.isDone;
    const message = this.props.message;
    const onDelete = this.props.onDelete;
    let h = <h1>CraiglistUsers</h1>;
    if (isDone === true) {
      return (
        <div className="ListOfCraiglistUser">
          <Base />
          {h}
          {craiglistUsers.map(craiglistUser => (
            <CraiglistUser
              onDelete={onDelete}
              key={craiglistUser.id}
              id={craiglistUser.id}
              email={craiglistUser.email}
              password={craiglistUser.password}
              isLoginFailed={craiglistUser.isLoginFailed}
              start_posting={craiglistUser.start_posting}
              onSubmit={onSubmit}
            ></CraiglistUser>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <Base />
          {h}
          {message}
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.fetchUsers();
  }
}

export default ListOfCraiglistUser;
