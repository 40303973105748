import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Utils } from '../utils/Utils';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import image from '../style/icon.jpg';
class Login extends Component {
  login = (e, onSubmit, st) => {
    e.preventDefault();
    var email = e.target.elements[0].value;
    var pwd = e.target.elements[1].value;
    onSubmit(email, pwd);
  };

  render() {
    const onSubmit = this.props.onSubmit;
    const message = this.props.message;

    if (Utils.isLoggedIn()) {
      return <Redirect to="/home"></Redirect>;
    }
    return (
      <div className="login">
        <Form className="form-inline" onSubmit={e => this.login(e, onSubmit)}>
          <Container fluid style={{ marginTop: 100, height: 'inherit' }}>
            <Row className="justify-content-md-center">
              <Col lg={4} md={8} sm={12} xs={12}>
                <Card style={{ borderRadius: 5 }} className="show">
                  <img
                    style={{
                      marginTop: -50,
                      alignSelf: 'center',
                      height: 300,
                      width: 300,
                    }}
                    src={image}
                    alt="logo"
                  />
                  <h2 style={{ marginTop: -50 }}>Admin Login</h2>
                  <br />
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                  />
                  <br />
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder="password"
                  />
                  <label>{message}</label>
                  <br />

                  <input
                    className="form-control  btn-dark"
                    type="submit"
                    name="login_button"
                    value="Login"
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    );
  }
}

export default Login;
