import React from 'react'
import axios from 'axios';
import {Constants} from "../utils/Constants";
import {Utils} from "../utils/Utils";

class FileUploadForm extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
  }

  async onSubmit(e) {
    e.preventDefault()
    let res = await this.uploadFile(e.target.files[0]);
    console.log(res.data);
    this.props.updateImage(res.data);
  }

  async uploadFile(file) {

    const formData = new FormData();
    formData.append('file', file)
    return axios.post(Constants.POST_IMAGES_API_PATH + Utils.getUser().id,
        formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        });
  }

  render() {
    return (
        <div>
          <label>Upload Post Image :</label>
        <input type="file" onChange={this.onSubmit}/>
        </div>
    )
  }

}

export default FileUploadForm;