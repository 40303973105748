import {
  ACTION_FETCH_TRANSACTION_LOGS_FAILED,
  ACTION_FETCH_TRANSACTION_LOGS_SUCCESS,
} from './actions';
import Axios from 'axios';
import { Constants } from '../utils/Constants';

export function fetchTransactionLogsSuccess(transactionLogs) {
  return {
    type: ACTION_FETCH_TRANSACTION_LOGS_SUCCESS,
    transactionLogs,
  };
}

export function fetchTransactionLogsFailed(message) {
  return {
    type: ACTION_FETCH_TRANSACTION_LOGS_FAILED,
    message,
  };
}

export function fetchTransactionLogs() {
  return function(dispatch) {
    return Axios.get(Constants.BASE_URL + 'transactionLogs/admin', {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then(response => {
        console.log(response.data);
        dispatch(fetchTransactionLogsSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchTransactionLogsFailed(error.message));
      });
  };
}
