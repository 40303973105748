import React, {Component} from 'react';
import Post from './Post';
import Base from './Base';

class ListOfPost extends Component {

  render() {
    const posts = this.props.posts;
    const onSubmit = this.props.onSubmit;
    const isDone = this.props.isDone;
    const message = this.props.message;
    const onDelete = this.props.onDelete;
    let h = <h1>Posts</h1>;
    if (isDone === true) {

      return <div className='ListOfPost'>
        <Base/>
        {h}
        <Post onSubmit={onSubmit} onDelete={onDelete}></Post>
        {

          posts.map(
              post => <Post onDelete={onDelete} key={post.id}
                            id={post.id} title={post.title}
                            body={post.body}
                            price={post.price}
                            image_path={post.image_path}
                            lastPostedLetgo={post.lastPostedLetgo}
                            lastPostedCraiglist={post.lastPostedCraiglist}
                            postingCity = {post.postingCity}
                            category = {post.category}
                            isLetgo = {post.isLetgo}
                            isLetgoHold = {post.isLetgoHold}
                            isOfferup = {post.isOfferup}
                            isOfferupHold = {post.isOfferupHold}
                            isCraiglist = {post.isCraiglist}
                            isCraiglistHold = {post.isCraiglistHold}
                            postalCode = {post.postalCode}
                            offerupCategory = {post.offerupCategory}
                            onSubmit={onSubmit}></Post>)
        }

      </div>;
    } else {
      return (<div><Base/>{h}{message}</div>)
    }
  }

  componentDidMount() {
    this.props.fetchPosts();
  }
}

export default ListOfPost;
