import { connect } from 'react-redux'
import ListOfPost from '../presentations/ListOfPost';
import {savePost, remove, fetchPosts} from '../actions/PostAction';

const mapStateToProps = state => ({
  posts: state.posts.posts,
isDone : state.posts.isDone,
message: state.posts.message
  
})

const mapDispatchToProps = dispatch => ({
  fetchPosts : () => dispatch(fetchPosts()),
   onSubmit : (post) =>dispatch(savePost(post)),
   onDelete : (post) => dispatch(remove(post))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfPost)
