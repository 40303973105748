import { ACTION_FETCH_POSTS_FAILED,ACTION_FETCH_POSTS_SUCCESS} from "./actions";
import Axios from "axios";
import { Constants } from "../utils/Constants";
import { Utils } from "../utils/Utils";

export function fetchPostsSuccess(posts){
    return ({
        type : ACTION_FETCH_POSTS_SUCCESS,
        posts
    });
}

export function fetchPostsFailed(message){
    return ({
        type : ACTION_FETCH_POSTS_FAILED,
        message
    });
}

export function fetchPosts(){

    return function(dispatch){

         return Axios.get(Constants.BASE_URL + "users/posts/" + Utils.getUser().id,{headers : Constants.REQUEST_HEADER_JSON})
         .then(response => {  console.log(response.data);
             dispatch(fetchPostsSuccess(response.data))
        })
         .catch(error => {
             dispatch(fetchPostsFailed(error.message))
            });
}
}

export function savePost(post){

    return function(dispatch){
      if (post.id === undefined || post.id === null){
        return Axios.post(Constants.BASE_URL + "users/posts",post,{headers : Constants.REQUEST_HEADER_JSON})
        .then(response => {

          dispatch(fetchPosts())
        })
        .catch(error => {
          dispatch(fetchPostsFailed(error.message));
          dispatch(fetchPosts())
        });
      }
      else {
        return Axios.put(Constants.BASE_URL + "users/posts", post,
            {headers: Constants.REQUEST_HEADER_JSON})
        .then(response => {

          dispatch(fetchPosts())
        })
        .catch(error => {
          dispatch(fetchPostsFailed(error.message));
          dispatch(fetchPosts())
        });
      }

    }
}


export function remove(post){

    return function(dispatch){

        return Axios.delete(Constants.BASE_URL + "users/posts/" +post.id,{headers : Constants.REQUEST_HEADER_JSON})

        .then(response => {

            dispatch(fetchPosts())
   })
    .catch(error => {
        dispatch(fetchPostsFailed(error.message));
        dispatch(fetchPosts())
       });
    }
}



