import React, { Component } from 'react';
import OfferupUser from './OfferupUser';
import Base from './Base';

class ListOfOfferupUser extends Component {
  render() {
    const offerupUsers = this.props.offerupUsers;
    const onSubmit = this.props.onSubmit;
    const isDone = this.props.isDone;
    const message = this.props.message;
    const onDelete = this.props.onDelete;
    let h = <h1>OfferupUsers</h1>;
    if (isDone === true) {
      return (
        <div className="ListOfOfferupUser">
          <Base />
          {h}
          {offerupUsers.map(offerupUser => (
            <OfferupUser
              onDelete={onDelete}
              key={offerupUser.id}
              id={offerupUser.id}
              email={offerupUser.email}
              password={offerupUser.password}
              isLoginFailed={offerupUser.isLoginFailed}
              start_posting={offerupUser.start_posting}
              onSubmit={onSubmit}
            ></OfferupUser>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <Base />
          {h}
          {message}
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.fetchUsers();
  }
}

export default ListOfOfferupUser;
