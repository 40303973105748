import { ACTION_FETCH_CRAIGLIST_USERS_FAILED, ACTION_FETCH_CRAIGLIST_USERS_SUCCESS } from "../actions/actions";

const craiglistUsers = (state =[], action) =>{

    switch (action.type) {
        case ACTION_FETCH_CRAIGLIST_USERS_SUCCESS:{
            return { craiglistUsers : action.craiglistUsers, isDone:true};
        }
        case ACTION_FETCH_CRAIGLIST_USERS_FAILED:{
            return {message : action.message, isDone:false};
        }
        default:
        return {message : "In progress...", isDone:false};
            
    }
};

export default craiglistUsers;