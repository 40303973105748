import {combineReducers} from 'redux';
import login from './login';
import signup from './signup';
import progress from './progress';
import user from './user';
import services from './services';
import posts from './posts';
import postLogs from "./postlogs";
import craiglistusers from "./craiglistusers";
import offerupusers from "./offerupusers"
import transactionLogs from "./transactionlogs"
export default combineReducers({
  login, signup, progress, user, services, posts, postLogs, craiglistusers, offerupusers,transactionLogs
})
