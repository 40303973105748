import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Badge, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

class PostLog extends Component {
  render() {
    const title = this.props.title;
    const postingCity = this.props.postingCity;
    const user = this.props.user || {};
    const postalCode = this.props.postalCode;
    const body = this.props.body;
    const type = this.props.type;
    const price = this.props.price;
    const createdAt = this.props.createdAt;
    const id = this.props.id;
    const status = (
      <Badge pill variant={this.props.isSuccess ? 'success' : 'danger'}>
        <FontAwesomeIcon icon={this.props.isSuccess ? faCheck : faTimes} />
      </Badge>
    );

    return (
      <tr className="PostLog">
        <td>{id} </td>
        <td className="align-left">{user.email} </td>
        <td>{status}</td>
        <td className="capitalize">{type}</td>
        <td className="align-left">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip" style={{ border: 'none' }}>
                <div className="align-left">
                  <div>
                    <b>Description: </b>
                    {body}
                  </div>
                  <div>
                    <b>Price:</b> {price}
                  </div>
                  <div>
                    <b>City:</b> {postingCity}{' '}
                  </div>
                  <div>
                    <b>Postal Code:</b> {postalCode}
                  </div>
                </div>
              </Tooltip>
            }
          >
            <Button variant="link" className="pd-0">
              {title}
            </Button>
          </OverlayTrigger>
        </td>
        <td>{new Date(createdAt).toLocaleTimeString()}</td>
        <td className="align-left">{new Date(createdAt).toUTCString()}</td>
      </tr>
    );
  }
}

export default PostLog;
