import { connect } from 'react-redux';
import { fetchPostLogs } from '../actions/PostLogAction';
import ListOfPostLog from '../presentations/ListOfPostLog';

const mapStateToProps = state => ({
  postLogs: state.postLogs.postLogs,
  users: state.postLogs.users,
  isDone: state.postLogs.isDone,
  message: state.postLogs.message,
});

const mapDispatchToProps = dispatch => ({
  fetchPostLogs: (limit, offset) => dispatch(fetchPostLogs(limit, offset)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListOfPostLog);
