import React, {Component} from 'react';
import {Constants} from "../utils/Constants";

class TransactionLog extends Component {

  render() {
    let user_id = this.props.user_id;
    let amount = this.props.amount;
    let status = this.props.status;
    let payment_id = this.props.payment_id;
    let createdAt = this.props.createdAt;
    let id = this.props.id;
    let billing_customer_id = this.props.billing_customer_id;
    return <div className='TransactionLog'>
      <div className='form-group'>
        <h5>TransactionLog # {id} </h5>({status})
        <label className='form-control'>User: {user_id}</label>
        <label className='form-control'>Amount: {amount}</label>
        <label className='form-control'>Status: {status}</label>
          <label className='form-control'>Payment Id: {payment_id}</label>
            <label className='form-control'>Billing Customer Id: {billing_customer_id}</label>
            <label className='form-control'>CreatedAt: {createdAt}</label>
        <br/>
      </div>

    </div>;
  }

  getImageHref = (image_path) => {
    if (image_path === '') {
      return ('');
    }
    return (
        <div>
          <a className='form-control'
             href={Constants.POST_IMAGES_PATH + image_path}>Image
          </a>
        </div>
    );
  }
}

export default TransactionLog;
