import { connect } from 'react-redux'
import ListOfService from '../presentations/ListOfService';
import {saveService, remove, fetchServices} from '../actions/ServiceAction';

const mapStateToProps = state => ({
  services: state.services.services,
isDone : state.services.isDone,
message: state.services.message
  
})

const mapDispatchToProps = dispatch => ({
  fetchServices : () => dispatch(fetchServices()),
   onSubmit : (service) =>dispatch(saveService(service)),
   onDelete : (service) => dispatch(remove(service))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfService)
