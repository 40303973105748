import {
  ACTION_FETCH_TRANSACTION_LOGS_FAILED,
  ACTION_FETCH_TRANSACTION_LOGS_SUCCESS
} from "../actions/actions";

const transactionLogs = (state = [], action) => {

  switch (action.type) {
    case ACTION_FETCH_TRANSACTION_LOGS_SUCCESS: {
      return {transactionLogs: action.transactionLogs, isDone: true};
    }
    case ACTION_FETCH_TRANSACTION_LOGS_FAILED: {
      return {message: action.message, isDone: false};
    }

    default:
      return {message: "In Progress...", isDone: false};

  }
};

export default transactionLogs;
