import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faChartPie,
  faUsers,
  faList,
} from "@fortawesome/free-solid-svg-icons";

class Base extends Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand>
          <NavLink className="navMenu" to="/users">
            Dashboard
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link>
              <NavLink className="navMenu" to="/users">
                USERS{" "}
                <FontAwesomeIcon style={{ fontSize: 18 }} icon={faUsers} />
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/logs" className="navMenu">
                LOGS{" "}
                <FontAwesomeIcon style={{ fontSize: 18 }} icon={faChartPie} />
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/transactionLog" className="navMenu">
                TRANSACTIONS{" "}
                <FontAwesomeIcon style={{ fontSize: 18 }} icon={faList} />
              </NavLink>
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link>
              <NavLink to="/logout" className="navMenu">
                LOG OUT{" "}
                <FontAwesomeIcon style={{ fontSize: 18 }} icon={faSignOutAlt} />
              </NavLink>
            </Nav.Link>
            {/* <Nav.Link>
              <NavLink to="/service" className="navMenu">
                Letgo Users
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/craiglistUser" className="navMenu">
                CraiglistUsers
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/offerupUser" className="navMenu">
                Offerup Users
              </NavLink>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Base;
