import {
  ACTION_FETCH_POST_LOGS_FAILED,
  ACTION_FETCH_POST_LOGS_SUCCESS,
} from './actions';
import Axios from 'axios';
import { Constants } from '../utils/Constants';

export function fetchPostLogsSuccess(data) {
  return {
    type: ACTION_FETCH_POST_LOGS_SUCCESS,
    data,
  };
}

export function fetchPostLogsFailed(message) {
  return {
    type: ACTION_FETCH_POST_LOGS_FAILED,
    message,
  };
}

export function fetchPostLogs(limit, offset) {
  return function(dispatch) {
    return Axios.get(
      `${Constants.BASE_URL}posts/logs/admin?limit=${limit}&offset=${offset}`,
      { headers: Constants.REQUEST_HEADER_JSON }
    )
      .then(response => {
        console.log(response.data);
        dispatch(fetchPostLogsSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchPostLogsFailed(error.message));
      });
  };
}
