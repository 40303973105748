import { ACTION_FETCH_POSTS_SUCCESS, ACTION_FETCH_POSTS_FAILED } from "../actions/actions";

const posts = (state =[], action) =>{

    switch (action.type) {
        case ACTION_FETCH_POSTS_SUCCESS:{
            return { posts : action.posts, tags:action.tags, isDone:true};
        }
        case ACTION_FETCH_POSTS_FAILED:{
            return {message : action.message, isDone:false};
        }
    
        default:
        return {message : "In Progress...", isDone:false};
            
    }
};

export default posts;