import { ACTION_FETCH_OFFERUP_USERS_SUCCESS, ACTION_FETCH_OFFERUP_USERS_FAILED } from "../actions/actions";

const offerupUsers = (state =[], action) =>{

    switch (action.type) {
        case ACTION_FETCH_OFFERUP_USERS_SUCCESS:{
            return { offerupUsers : action.offerupUsers, isDone:true};
        }
        case ACTION_FETCH_OFFERUP_USERS_FAILED:{
            return {message : action.message, isDone:false};
        }
        default:
        return {message : "In progress...", isDone:false};

    }
};

export default offerupUsers;
