export const ACTION_LOGIN = "LOGIN";
export const ACTION_PROGRESS = "PROGRESS";
export const ACTION_PROGRESS_DONE = "PROGRESS_DONE";
export const ACTION_LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const ACTION_LOGIN_FAILED = "LOGIN_FAILED";
export const ACTION_SIGNUP_SUCCESSFUL = "SIGNUP_SUCCESSFUL";
export const ACTION_SIGNUP_FAILED = "SIGNUP_FAILED";
export const ACTION_FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const ACTION_FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const ACTION_FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const ACTION_FETCH_SERVICES_FAILED = "FETCH_SERVICES_FAILED";
export const ACTION_FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const ACTION_FETCH_POSTS_FAILED = "FETCH_POSTS_FAILED";
export const ACTION_FETCH_POST_LOGS_SUCCESS = "FETCH_POST_LOGS_SUCCESS";
export const ACTION_FETCH_POST_LOGS_FAILED = "FETCH_POST_LOGS_FAILED";
export const ACTION_FETCH_CRAIGLIST_USERS_SUCCESS = "FETCH_CRAIGLIST_USERS_SUCCESS";
export const ACTION_FETCH_CRAIGLIST_USERS_FAILED = "FETCH_CRAIGLIST_USERS_FAILED";
export const ACTION_FETCH_OFFERUP_USERS_SUCCESS = "ACTION_FETCH_OFFERUP_USERS_SUCCESS";
export const ACTION_FETCH_OFFERUP_USERS_FAILED = "ACTION_FETCH_OFFERUP_USERS_FAILED";
export const ACTION_FETCH_TRANSACTION_LOGS_SUCCESS = "FETCH_TRANSACTION_LOGS_SUCCESS";
export const ACTION_FETCH_TRANSACTION_LOGS_FAILED = "FETCH_TRANSACTION_LOGS_FAILED";
