import { ACTION_FETCH_SERVICES_SUCCESS, ACTION_FETCH_SERVICES_FAILED } from "../actions/actions";

const services = (state =[], action) =>{

    switch (action.type) {
        case ACTION_FETCH_SERVICES_SUCCESS:{
            return { services : action.services, isDone:true};
        }
        case ACTION_FETCH_SERVICES_FAILED:{
            return {message : action.message, isDone:false};
        }
        default:
        return {message : "In progress...", isDone:false};
            
    }
};



export default services;