import React, { Component } from 'react';
import { Utils } from '../utils/Utils';

class OfferupUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginFailed: props.isLoginFailed === 1 ? true : false,
      start_posting: props.start_posting === 1 ? true : false,
    };
  }
  submit = (e, key, onSubmit) => {
    e.preventDefault();
    let id = key;
    let email = e.target.elements[0].value;
    let password = e.target.elements[1].value;
    let isLoginFailed = this.state.isLoginFailed ? 1 : 0;
    let start_posting = this.state.start_posting ? 1 : 0;

    let offerupUser = {
      id: id,
      user_id: Utils.getUser().id,
      email: email,
      password: password,
      isLoginFailed: isLoginFailed,
      start_posting: start_posting,
      isActive: 1,
    };
    onSubmit(offerupUser);
  };
  render() {
    const onSubmit = this.props.onSubmit;
    const onDelete = this.props.onDelete;
    let email = this.props.email;
    let password = this.props.password;
    const key = this.props.id;
    let submitText = 'Update';
    if (key == null) {
      submitText = 'Add';
      email = '';
      password = '';
    }
    return (
      <div className="OfferupUser">
        <form
          className="form-inline"
          onSubmit={e => this.submit(e, key, onSubmit)}
        >
          <div className="form-group">
            <h5>OfferupUser</h5>
            <input
              className="form-control"
              type="text"
              name="email"
              placeholder="Email"
              defaultValue={email}
            />

            {key != null ? (
              <div></div>
            ) : (
              <input
                className="form-control"
                type="text"
                name="password"
                placeholder="password"
                defaultValue={password}
              />
            )}
            <label className="form-control">
              IsLoginFailed :
              <input
                type="checkbox"
                name="isLoginFailed"
                checked={this.state.isLoginFailed}
                onChange={this.handleInputChange}
              />
            </label>

            <label className="form-control">
              Start Posting :
              <input
                type="checkbox"
                name="start_posting"
                checked={this.state.start_posting}
                onChange={this.handleInputChange}
              />
            </label>
            <input
              className="btn btn-primary"
              type="submit"
              value={submitText}
            />
          </div>
        </form>
        <br />
        {this.delete(onDelete, key)}
      </div>
    );
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  delete = (onDelete, key) => {
    if (key !== undefined && key !== null) {
      let offerupUser = {
        id: key,
        isActive: 0,
      };
      return <button onClick={e => onDelete(offerupUser)}>Delete</button>;
    }
  };
}

export default OfferupUser;
