import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faVoteYea,
  faCopy,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

class StatusColumn extends React.Component {
  render() {
    return (
      <>
        <OverlayTrigger
          overlay={<Tooltip style={{ border: "none" }}>Copy password</Tooltip>}
        >
          <span className="hoverPointer">
            <Badge variant="link" style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon icon={faCopy} style={{ color: "#c7c3c3d1" }} />
            </Badge>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip style={{ border: "none" }}>
              Login
              {this.props.isLoginFailed ? " fail" : " success"}
            </Tooltip>
          }
        >
          <span
            className="hoverPointer"
            onClick={() =>
              this.props.changeState(
                "isLoginFailed",
                this.props.element,
                this.props.prefix
              )
            }
          >
            <Badge variant="link" style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon
                style={{
                  color: this.props.isLoginFailed ? "#df6c6c" : "#3ea23e",
                }}
                icon={faSignInAlt}
              />
            </Badge>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip style={{ border: "none" }}>
              Posting
              {this.props.start_posting ? " success" : " fail"}
            </Tooltip>
          }
        >
          <span
            className="hoverPointer"
            onClick={() =>
              this.props.changeState(
                "start_posting",
                this.props.element,
                this.props.prefix
              )
            }
          >
            <Badge variant="link" style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon
                style={{
                  color: this.props.start_posting ? "#3ea23e" : "#df6c6c",
                }}
                icon={faVoteYea}
              />
            </Badge>
          </span>
        </OverlayTrigger>
      </>
    );
  }
}

export default StatusColumn;
