import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import BaseRouter from "./router/BaseRouter";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import axios from "axios";
import { Utils } from "./utils/Utils";
import 'bootstrap/dist/css/bootstrap.min.css';

axios.interceptors.request.use(function(config) {
  const user = Utils.getUser();
  if (user !== undefined && user !== null) {
    config.headers["x-access-token"] = user.token;
  }
  return config;
});

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunk // lets us dispatch() functions
  )
);

ReactDOM.render(<BaseRouter store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
