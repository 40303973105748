import {
  ACTION_FETCH_CRAIGLIST_USERS_SUCCESS,
  ACTION_FETCH_CRAIGLIST_USERS_FAILED,
} from './actions';
import Axios from 'axios';
import { Constants } from '../utils/Constants';

export function fetchUsersSuccess(craiglistUsers) {
  return {
    type: ACTION_FETCH_CRAIGLIST_USERS_SUCCESS,
    craiglistUsers,
  };
}

export function fetchUsersFailed(message) {
  return {
    type: ACTION_FETCH_CRAIGLIST_USERS_FAILED,
    message,
  };
}

export function fetchUsers() {
  return function(dispatch) {
    return Axios.get(Constants.BASE_URL + 'users/craiglist/admin', {
      headers: Constants.REQUEST_HEADER_JSON,
    })
      .then(response => {
        console.log(response.data);
        dispatch(fetchUsersSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchUsersFailed(error.message));
      });
  };
}

export function saveUser(craiglistUser) {
  return function(dispatch) {
    if (craiglistUser.id === undefined || craiglistUser.id === null) {
      return Axios.post(Constants.BASE_URL + 'users/craiglist', craiglistUser, {
        headers: Constants.REQUEST_HEADER_JSON,
      })
        .then(response => {
          dispatch(fetchUsers());
        })
        .catch(error => {
          dispatch(fetchUsersFailed(error.message));
          dispatch(fetchUsers());
        });
    } else {
      return Axios.put(
        Constants.BASE_URL + 'users/craiglist/admin',
        craiglistUser,
        { headers: Constants.REQUEST_HEADER_JSON }
      )
        .then(response => {
          dispatch(fetchUsers());
        })
        .catch(error => {
          dispatch(fetchUsersFailed(error.message));
          dispatch(fetchUsers());
        });
    }
  };
}

export function remove(craiglistUser) {
  return function(dispatch) {
    return Axios.delete(
      Constants.BASE_URL + 'users/craiglist/admin/' + craiglistUser.id,
      { headers: Constants.REQUEST_HEADER_JSON }
    )

      .then(response => {
        dispatch(fetchUsers());
      })
      .catch(error => {
        dispatch(fetchUsersFailed(error.message));
        dispatch(fetchUsers());
      });
  };
}
