import {connect} from 'react-redux'
import ListOfOfferupUser from '../presentations/ListOfOfferupUser';
import {saveUser, remove, fetchUsers} from '../actions/OfferupUserAction';

const mapStateToProps = state => ({
    offerupUsers: state.offerupusers.offerupUsers,
    isDone: state.offerupusers.isDone,
    message: state.offerupusers.message

})

const mapDispatchToProps = dispatch => ({
    fetchUsers: () => dispatch(fetchUsers()),
    onSubmit: (user) => dispatch(saveUser(user)),
    onDelete: (user) => dispatch(remove(user))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOfOfferupUser)
