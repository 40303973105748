import React, { Component } from 'react';
import Service from './Service';
import Base from './Base';

class ListOfService extends Component {
  render() {
    const services = this.props.services;
    const onSubmit = this.props.onSubmit;
    const isDone = this.props.isDone;
    const message = this.props.message;
    const onDelete = this.props.onDelete;
    let h = <h1>Letgo Users</h1>;
    if (isDone === true) {
      return (
        <div className="ListOfService">
          <Base />
          {h}
          {services.map(service => (
            <Service
              onDelete={onDelete}
              key={service.id}
              id={service.id}
              email={service.email}
              userId={service.user_id}
              password={service.password}
              isLoginFailed={service.isLoginFailed}
              start_posting={service.start_posting}
              onSubmit={onSubmit}
            ></Service>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <Base />
          {h}
          {message}
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.fetchServices();
  }
}

export default ListOfService;
