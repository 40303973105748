import React from 'react';

import {Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class EmailTooltip extends React.Component {
  render() {
    return (
        <OverlayTrigger
        placement={this.props.placement}
        overlay={
          <Tooltip style={{ border: "none" }}>
            <div>
              <b>Created </b>
              {new Date(this.props.createdAt).toDateString()}
            </div>
            <div>
              <b>Updated </b>
              {new Date(this.props.updatedAt).toDateString()}
            </div>
          </Tooltip>
        }
      >
        <Button className="pd-0" variant="link">
          {this.props.email}
        </Button>
      </OverlayTrigger>
    );
  }
}

export default EmailTooltip;