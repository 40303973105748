import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import App from '../containers/App';
import HomeContainer from "../containers/HomeContainer";
import ProgressContainer from "../containers/ProgressContainer";
import ServiceContainer from "../containers/ServiceContainer";
import CraiglistUserContainer from "../containers/CraiglistUserContainer";
import PostContainer from "../containers/PostContainer";
import Logout from "../containers/LogoutContainer";
import { Utils } from '../utils/Utils';
import PostLogContainer from "../containers/PostLogContainer";
import OfferupUserContainer from "../containers/OfferupUserContainer";
import TransactionLogContainer from "../containers/TransactionLogContainer"
import Users from "../containers/UsersContainer"
class BaseRouter extends Component {

  onLoggedIN = () => {
    if (Utils.isLoggedIn() === true) {
      return (
        <div>
          <Route path="/home" component={HomeContainer} />
          <Route path="/service" component={ServiceContainer} />
          <Route path="/craiglistUser" component={CraiglistUserContainer} />
          <Route path="/offerupUser" component={OfferupUserContainer} />
          <Route path="/post" component={PostContainer} />
          <Route path="/logs" component={PostLogContainer} />
          <Route path="/transactionLog" component={TransactionLogContainer} />
          <Route path="/logout" component={Logout} />
          <Route path="/Users" component={Users} />
        </div>
      );
    }
  }

  render() {
    const store = this.props.store;
    return (
      <Provider store={store}>
        <Router>
          <div>
            <ProgressContainer />
            <Route exact path="/" component={App} />
            <PrivateRoute path="/home" component={HomeContainer} />
            <PrivateRoute path="/service" component={ServiceContainer} />
            <PrivateRoute path="/craiglistUser" component={CraiglistUserContainer} />
            <PrivateRoute path="/offerupUser" component={OfferupUserContainer} />
            <PrivateRoute path="/post" component={PostContainer} />
            <PrivateRoute path="/logs" component={PostLogContainer} />
            <Route path="/transactionLog" component={TransactionLogContainer} />
            <PrivateRoute path="/logout" component={Logout} />
            <Route path="/Users" component={Users} />
          </div>
        </Router>
      </Provider>

    );

  }

}

export default BaseRouter;

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        Utils.isLoggedIn() === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  )
};
